

$(document).ready(function () {
  const marketForm = $('.structureMarketForm')

  if (marketForm.length) {
    $.each(marketForm, function (index, value) {
      const randomId = $(this).attr('randomId')
      const title = $(this).data('title')
      const ingress = $(this).data('ingress')
      const form = $(this).data('form')

      if (title) {
        $(`.formTitle.${randomId}`).text(title)
      }

      if (ingress) {
        $(`.formIngress.${randomId}`).text(ingress)
      }

      if (form) {
        const processedForm = tidy(form)
        $(`.formHtml.${randomId}`).append(processedForm)
      }
    })
  }

})


function tidy(html) { // using the browser to validate broken HTML
  var d = document.createElement('div');
  d.innerHTML = html;
  return d.innerHTML;
}
